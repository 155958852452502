import { memo } from "react";
import Head from "next/head";

const Header = ({ browserTitle = "WellFitly: Empowering Your Wellness Journey with the Right Professionals" }) => (
  <Head>
    <title>{browserTitle}</title>
    <meta
      name="description"
      content="Find the best independent fitness and wellness providers in your city. Discover a community of wellness and fitness professionals on WellFitly, your go-to platform for personalized health and fitness services. Find the right expert to guide your wellness journey, from personal trainers and yoga instructors to lifestyle coaches and more. Achieve your health goals with WellFitly today!"
    />
    <meta
      name="keywords"
      content="WellFitly, Wellness Platform, Fitness Professional, Fitness Enthusiast, Personal Trainers, Yoga Instructors, Pilates Instructors, Lifestyle Coaches, Masseuses, Health and Fitness, Fitness Services, Wellness Industry, Online Fitness Services, Wellness Goals, Fitness Goals, Brand Engagement, Business Opportunities in Wellness, Wellness Collaboration, Wellness Networking, Wellness Community, Online Training, Online Yoga Classes, Online Pilates Classes, Personal Wellness, Physical Fitness, Healthy Lifestyle, Achieving Fitness Goals, WellFitly App, Wellness App, Fitness App, WellFitly Platform, Wellness Professionals Network, Fitness Professionals Network, Wellness, Fitness, Health, Personal Trainer, Gyms, Events, Independent Providers, Professionals Physiotherapist, Massage, Swimming, Swimming Instructor, Yoga Instructor, Lifestyle Coach, Masseuse, Pilates Instructor, Wellness Professional, Fitness Enthusiast, Wellness Platform, Fitness Services"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@WellFitly" />
    <meta
      name="twitter:title"
      content="WellFitly: Your Fitness and Wellness Platform"
    />
    <meta
      name="twitter:description"
      content="WellFitly connects fitness professionals and enthusiasts. Find trainers, yoga instructors, lifestyle coaches and more to achieve your wellness goals."
    />
    <meta
      name="twitter:image"
      content="https://www.wellfitly.com/WF.png"
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.wellfitly.com" />
    <meta
      property="og:title"
      content="WellFitly: Your Fitness and Wellness Platform"
    />
    <meta
      property="og:description"
      content="WellFitly connects fitness professionals and enthusiasts. Find trainers, yoga instructors, lifestyle coaches and more to achieve your wellness goals."
    />
    <meta
      property="og:image"
      content="https://www.wellfitly.com/WF.png"
    />
    <meta name="author" content="WellFitly" />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <link rel="canonical" href="https://www.wellfitly.com" />
    <link rel="icon" href="/favicon.ico" />
    <link
      rel="preload"
      href="/fonts/YesevaOne-Regular.ttf"
      as="font"
      crossOrigin=""
    />
  </Head>
);

export default memo(Header);
