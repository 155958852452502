import {
  formatForLocationOptions,
  getCities,
  getCountries,
  getStates,
} from "utils/locations";
import { dateParts, formatForDateOptions } from "utils/numberFormatter";

export const PUBLIC = "public";
export const PRIVATE = "private";
export const PROVIDER = "Provider";
export const CLIENT = "Client";
export const ADMIN_USER = "Admin";
export const BUSINESS_USER = "Business";
export const EDITOR_USER = "Editor";
export const DEFAULT_COUNTRY = "Nigeria";

export const ONLINE = "online";
export const OFFLINE = "offline";
export const VERIFIED = "verified";
export const UNVERIFIED = "unverified";
export const FEATURED = "featured";
export const NOT_FEATURED = "not_featured";
export const DELETED_DOCUMENT = "deleted_document";

export const ANDROID = "Android";
export const IOS = "iOS";
export const IOS_BUTTON_ID = "btn-appstore";
export const IOS_LINK_ID = "appstore_url";
export const IOS_BUTTON_ALT = "WellFitly iOS App Download";
export const ANDROID_BUTTON_ID = "btn-playstore";
export const ANDROID_LINK_ID = "playstore-url";
export const ANDROID_BUTTON_ALT = "WellFitly Android App Download";
export const PASSWORD_RESET_REDIRECT_URL =
  "https://wellfitly.com/forgot/password-reset";

export const USER_CONSENT_COOKIE_KEY = "_wfcc";
export const MEDIA_CONSENT_COOKIE_KEY = "_wfmc";
export const UPDATE_CONSENT_COOKIE_KEY = "_wfuc";

export const topicOptions = Object.freeze([
  { label: "My account", value: "My Account" },
  { label: "Media upload", value: "Media Upload" },
  { label: "My inbox", value: "My Inbox" },
  { label: "My services", value: "My Services" },
  { label: "Report a user", value: "Report a user" },
  { label: "General enquiry", value: "General" },
]);

export const durationOptions = Object.freeze([
  { label: "60mins", value: "60mins" },
  { label: "90mins", value: "90mins" },
  { label: "120min", value: "120mins" },
  { label: "180mins", value: "180mins" },
  { label: "Half Day (6hrs)", value: "Half Day (6hrs)" },
  { label: "Full Day (12hrs)", value: "Full Day (12hrs)" },
  { label: "1 Week", value: "1 Week" },
  { label: "2 Weeks", value: "2 Weeks" },
  { label: "3 Weeks", value: "3 Weeks" },
  { label: "1 Month", value: "1 Month" },
  { label: "2 Months", value: "2 Months" },
  { label: "3 Months", value: "3 Months" },
]);

export const bankOptions = Object.freeze([
  { label: "Access Bank", value: "Access Bank" },
  { label: "Citibank", value: "Citibank" },
  { label: "Diamond Bank", value: "Diamond Bank" },
  { label: "Dynamic Standard Bank", value: "Dynamic Standard Bank" },
  { label: "Ecobank Nigeria", value: "Ecobank Nigeria" },
  { label: "Fidelity Bank Nigeria", value: "Fidelity Bank Nigeria" },
  { label: "First Bank of Nigeria", value: "First Bank of Nigeria" },
  {
    label: "First City Monument Bank",
    value: "First City Monument Bank",
  },
  { label: "Guaranty Trust Bank", value: "Guaranty Trust Bank" },
  { label: "Heritage Bank Plc", value: "Heritage Bank Plc" },
  { label: "Jaiz Bank", value: "Jaiz Bank" },
  { label: "Keystone Bank Limited", value: "Keystone Bank Limited" },
  { label: "Providus Bank Plc", value: "Providus Bank Plc" },
  { label: "Polaris Bank", value: "Polaris Bank" },
  {
    label: "Stanbic IBTC Bank Nigeria Limited",
    value: "Stanbic IBTC Bank Nigeria Limited",
  },
  {
    label: "Standard Chartered Bank",
    value: "Standard Chartered Bank",
  },
  { label: "Sterling Bank", value: "Sterling Bank" },
  {
    label: "Suntrust Bank Nigeria Limited",
    value: "Suntrust Bank Nigeria Limited",
  },
  { label: "Union Bank of Nigeria", value: "Union Bank of Nigeria" },
  { label: "United Bank for Africa", value: "United Bank for Africa" },
  { label: "Unity Bank Plc", value: "Unity Bank Plc" },
  { label: "Wema Bank", value: "Wema Bank" },
  { label: "Zenith Bank", value: "Zenith Bank" },
]);

export const currencySymbol = Object.freeze({
  NGN: "₦",
  USD: "$",
});

export const currencyOptions = Object.freeze([
  { label: "Naira", value: "NGN" },
  { label: "US Dollar", value: "USD" },
]);

export const genderOptions = Object.freeze([
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
  { label: "Other", value: "Other" },
  { label: "Unspecified", value: "Unspecified" },
]);

export const accountOptions = Object.freeze([
  { label: "Client/Trainee", value: "Client" },
  { label: "Coach/Trainer", value: "Provider" },
  { label: "Gym/Facilities Business", value: "Business" },
]);

export const serviceLocationOptions = Object.freeze([
  { label: "Gym/Studio/Outdoor", value: "Gym/Studio/Outdoor" },
  { label: "My Home", value: "My Home" },
  { label: "Online", value: "Online" },
]);

export const roleOptions = Object.freeze([
  { label: "Aerobics Coach", value: "Aerobic Coach" },
  { label: "Boxing Coach", value: "Boxing Coach" },
  { label: "Dance Coach", value: "Dance Coach" },
  { label: "Dietitian/Nutritionist", value: "Nutritionist" },
  { label: "Fitness Coach", value: "Fitness Coach" },
  { label: "Karate Instructor", value: "Karate Instructor" },
  { label: "Kick-Boxing Instructor", value: "Kick-Boxing Instructor" },
  { label: "Lawn Tennis Coach", value: "Lawn Tennis Coach" },
  { label: "Martial Arts Coach", value: "Martial Arts Coach" },
  { label: "Masseuse/Massage Therapist", value: "Masseuse" },
  { label: "Pilate Instructor", value: "Pilate Instructor" },
  { label: "Physiotherapist", value: "Physiotherapist" },
  { label: "Sports Coach", value: "Sports Coach" },
  { label: "Swimming Coach", value: "Swimming Coach" },
  { label: "Therapist/Life Coach", value: "Therapist/Life Coach" },
  { label: "Yoga Instructor", value: "Yoga Instructor" },
  { label: "Zumba Instructor", value: "Zumba Instructor" },
]);

export const expertiseOptions = Object.freeze([
  "Adaptive Fitness for Special Needs",
  "Aerial and Pole Fitness",
  "Animal Flow Workouts",
  "Aquatic Exercises",
  "Athletic Performance Enhancement",
  "Barre Workouts",
  "Bodybuilding",
  "Breathwork and Relaxation Techniques",
  "Calisthenics and Bodyweight Training",
  "Cardiovascular Conditioning",
  "Circuit Training",
  "Combat Sports Training",
  "Core and Balance",
  "CrossFit and Powerlifting",
  "Dance-based Workouts",
  "Diving and Underwater Fitness",
  "Endurance Training",
  "Flexibility and Mobility",
  "Functional Training",
  "Geriatric Physical Therapy",
  "Golf Fitness Training",
  "Group Fitness Instruction",
  "High-Intensity Interval Training (HIIT)",
  "Holistic Health Coaching",
  "Individual Sports Coaching",
  "Injury Prevention and Rehabilitation",
  "Kettlebell Training",
  "Kids' Fitness Programs",
  "Martial Arts and Self-defense",
  "Meditation and Mindfulness",
  "Military Fitness Preparation",
  "Nutrition",
  "Nutritional Therapy for Specific Conditions",
  "Obstacle Course Training",
  "Open Water Swimming Training",
  "Outdoor Adventure Fitness",
  "Parkour and Freerunning",
  "Pilates and Yoga Specializations",
  "Plant-based Nutrition Coaching",
  "Postnatal and Prenatal Fitness",
  "Prehabilitation Training",
  "Recreational Sports Coaching",
  "Rhythmic Gymnastics Coaching",
  "Rock Climbing Fitness",
  "Self-defense Techniques and Strategy",
  "Senior Fitness Programs",
  "Skateboarding and Inline Skating Fitness",
  "Ski and Snowboard Fitness Training",
  "Spinning and Indoor Cycling",
  "Sport Psychology Coaching",
  "Sports Nutrition and Diet Planning",
  "Sports Specific Training",
  "Strength Training",
  "Stress Management Coaching",
  "Swimming Technique Improvement",
  "Synchronized Swimming Coaching",
  "Tactical Fitness Training for Law Enforcement",
  "Team Sports Training",
  "Trail Running Coaching",
  "Triathlon and Multisport Coaching",
  "Vegan Nutrition Coaching",
  "Weight Loss",
  "Weight Management and Dietetics",
  "Wellbeing and Mental Health",
]);

export const placeOptions = Object.freeze([
  { label: "Aerobic Studio", value: "Aerobic Studio" },
  { label: "Basketball Court", value: "Basketball Court" },
  { label: "Football Pitch", value: "Football Pitch" },
  { label: "Gym", value: "Gym" },
  { label: "Lawn Tennis Court", value: "Lawn Tennis Court" },
  { label: "Pilate Studio", value: "Pilate Studio" },
  { label: "Spa", value: "Spa" },
  { label: "Swimming Pool", value: "Swimming Pool" },
]);

export const cityOptions = Object.freeze([
  { label: "Abuja", value: "Abuja" },
  { label: "Accra", value: "Accra" },
  { label: "Asaba", value: "Asaba" },
  { label: "Benin City", value: "Benin City" },
  { label: "Calabar", value: "Calabar" },
  { label: "Cape Town", value: "Cape Town" },
  { label: "Durban", value: "Durban" },
  { label: "Enugu", value: "Enugu" },
  { label: "Ibadan", value: "Ibadan" },
  { label: "Johannesburg", value: "Johannesburg" },
  { label: "Kigali", value: "Kigali" },
  { label: "Kumasi", value: "Kumasi" },
  { label: "Lagos", value: "Lagos" },
  { label: "London", value: "London" },
  { label: "Nairobi", value: "Nairobi" },
  { label: "Owerri", value: "Owerri" },
  { label: "Port-Harcourt", value: "Port-Harcourt" },
  { label: "Takoradi", value: "Takoradi" },
]);

export const documentOptions = Object.freeze([
  { label: "CAC/Business Document", value: "CAC/Business Document" },
  { label: "Driver License", value: "Driver License" },
  { label: "Passport", value: "Passport" },
  { label: "National ID", value: "National ID" },
]);

export const dateOptions = Object.freeze({
  years: dateParts.years.map(formatForDateOptions),
  months: dateParts.months.map(formatForDateOptions),
  days: dateParts.days.map(formatForDateOptions),
});

export const locationOptions = Object.freeze({
  countries: getCountries().map(formatForLocationOptions),
  states: (country) => getStates(country).map(formatForLocationOptions),
  cities: (state) => getCities(state).map(formatForLocationOptions),
});

export const COLLECTIONS = Object.freeze({
  profile: "profiles",
  galleries: "galleries",
  subscription_log: "subscription_log",
  settings: "settings",
  services: "services",
  faqs: "faqs",
  support_contact: "support_contact",
  rooms: "rooms",
  conversations: "conversations",
  offers: "offers",
  testimonials: "testimonials",
  web_content: "web_content",
  web_faqs: "web_faqs",
  notifications_subscription: "notifications_subscription",
  users: "users",
  users_settings: "users_settings",
  verification_documents: "verification_documents",
  inbox: "inbox",
  support_messages: "support_messages",
  livepeer_videos: "livepeer_videos",
  places: "places",
  providers_page_views: "providers_page_views",
  blog_page_views: "blog_page_views",
  blog: "blog",
  bookings: "bookings",
  track_occurrences: "track_occurrences",
});

export const STORAGE = Object.freeze({
  galleryBucket: "photo-gallery",
  documentsBucket: "documents-store",
  avatarBucket: "avatar",
});

export const UPLOAD = Object.freeze({
  GALLERY: "GALLERY",
  DOCUMENT: "DOCUMENT",
  AVATAR: "AVATAR",
});

export const FIELDS = Object.freeze({
  id: "id",
  firstname: "firstname",
  lastname: "lastname",
  account: "account",
  owner: "owner",
  user_id: "user_id",
  user_email: "user_email",
  room_id: "room_id",
  room: "room",
  city: "city",
  state: "state",
  active: "active",
  expertise: "expertise",
  expertise_area: "expertise_area",
  gender: "gender",
  tag: "tag",
  featured: "featured",
  email: "email",
  calling: "calling",
  messaging: "messaging",
  push: "push",
  visible: "visible",
  gallery: "gallery",
  created_at: "created_at",
  recipient: "recipient",
  sender: "sender",
  location: "location",
  deleted: "deleted",
  deleted_date: "deleted_date",
  is_live: "is_live",
  is_verified: "verified",
  verified: "verified",
  playback_id: "playback_id",
  currency_symbol: "currency_symbol",
  currency: "currency",
  category: "category",
  category_slug: "category_slug",
  last_login: "last_login",
  read: "read",
  activation_requested: "activation_requested",
  slug: "slug",
  transaction_id: "transaction_id",
  timestamp: "timestamp",
  client_checkout: "client_checkout",
  client_checkout_date: "client_checkout_date",
  provider_checkout: "provider_checkout",
  provider_checkout_date: "provider_checkout_date",
  client_id: "client_id",
  provider_id: "provider_id",
  provider_cashout_request: "provider_cashout_request",
  provider_cashout_request_date: "provider_cashout_request_date",
  provider_cashout: "provider_cashout",
  provider_cashout_date: "provider_cashout_date",
  provider_is_paid: "provider_is_paid",
  question: "question",
  tracker: "tracker",
});

export const AVATAR_STORAGE_URL = process.env.NEXT_PUBLIC_AVATAR_STORAGE_URL;
export const DOCUMENT_STORAGE_URL =
  process.env.NEXT_PUBLIC_DOCUMENT_STORAGE_URL;
export const PUBLIC_URL = process.env.NEXT_PUBLIC_PUBLIC_URL;
export const GALLERY_STORAGE_URL = process.env.NEXT_PUBLIC_GALLERY_STORAGE_URL;

export const WHATSAPP_URL = "https://wa.me/";

export const ORDER_BY_ID = "id";
export const ORDER_BY_RATING = "rating";
export const ORDER_BY_PRIORITY = "priority";

export const IMAGE_CONTENT_TYPE = Object.freeze({
  png: "image/png",
});

export const currency = "NGN";

export const SERVICE_FEE = Object.freeze({
  min: 10000,
  max: 10000000,
});

export const CHAT = Object.freeze({
  initializing: "Initializing",
  resuming: "Resuming",
  starting: "Starting",
});

export const WELLFITLY = "WellFitly";

export const SERVICE_LIMIT = 3;

export const PAGE_SIZE = 11;

export const PLATFORM_PERCENTAGE = 0.2;

export const BLOG_PAGE_SIZE = 200;

export const DEFAULT_OFFSET = 0;

export const ADMIN_PAGE_SIZE = 51;

export const SERVICE_LIMIT_MESSAGE =
  "You have reached your service listing limit!";

export const EMAIL_EXIST_MESSAGE = "An account with this email already exists";

export const contentTag = Object.freeze({
  testimonialsPage: "testimonial-page",
  gettingStartedPage: "getting-started",
  forProvidersPage: "for-providers",
  forIndividualsPage: "for-individuals",
  communityGuidelinesPage: "community-guidelines",
  cookiesPolicyPage: "cookies-policy",
  privacyPolicyPage: "privacy-policy",
  termsOfServicePage: "terms-of-service",
  homePageHero: "home-hero",
  faqsPage: "faqs-intro",
  aboutPage: "about",
  locationsPage: "locations",
  eventsPage: "events",
  collaborationPage: "collaboration",
});

export const featuredImage = Object.freeze({
  [contentTag.forIndividualsPage]: "/images/wellness-client.png",
  [contentTag.forProvidersPage]: "/images/wellness-pro.png",
  [contentTag.aboutPage]: "/images/wellness.png",
  [contentTag.locationsPage]: "/images/locations.jpg",
  [contentTag.eventsPage]: "/images/events.jpg",
  [contentTag.collaborationPage]: "/images/collaborate.jpg",
});

export const ALLOWED_FILE_EXT = Object.freeze(["png", "jpg", "jpeg", "pdf"]);

export const MAX_FILE_SIZE = 5; // size in MB

export const defaultProviderListQuery =  `id, firstname, lastname, email, avatar, about, city, state, location, account, verified, slug, expertise_area, instagram, tiktok, snapchat, twitter, youtube, lemon8, onlyfans`;