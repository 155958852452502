const getYears = (startYear = 1950) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() - 18;
  const years = [];
  for (let i = startYear; i <= currentYear; i++) {
    years.push(startYear++);
  }
  return years;
};

const getMonths = () => {
  return Object.freeze([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
};

const getDays = () => {
  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }
  return days;
};

export const formatForDateOptions = (year) => ({ label: year, value: year });

export const dateParts = Object.freeze({
  years: getYears(),
  months: getMonths(),
  days: getDays(),
});
