import cities from "./data/cities.json";
import states from "./data/states.json";
import countries from "./data/countries.json";

const ALLOWED_COUNTRIES = [
  "Nigeria",
  "Ghana",
  "United Kingdom",
  "South Africa",
  "Kenya",
  "Rwanda",
];

export const getCountries = () => {
  return countries.filter((country) => ALLOWED_COUNTRIES.includes(country.name)
  );
};

export const getStates = (country) => {
  return states.filter((state) => state.country === country?.toLowerCase());
};

export const getCities = (state) => {
  return cities.filter((city) => city.state === state?.toLowerCase());
};

export const formatForLocationOptions = ({ name }) => ({
  label: name,
  value: name,
});
